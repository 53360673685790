<template>
  <TourTooltip
    :steps="steps"
    :has-previous-step="hasPreviousStep"
    :has-next-step="hasNextStep"
    @previous="previousStep($event)"
    @next="nextStep($event)"
    @skip="$emit('skip')"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TourTooltip from '@/components/TourTooltip'

export default {
  name: 'TourVideoPlayer',
  components: {
    TourTooltip,
  },
  data() {
    return {
      hasPreviousStep: true,
      hasNextStep: true,
      steps: [
        {
          target: '#v-step-6',
          content: `
            <h4 class="v-step__title">Assista ao vídeo</h4>
            <span class="v-step__body">
              Aqui você poderá assistir à sua videoaula.
            </span>`,
          params: {
            placement: this.setAlignPlacementDeviceLarge(),
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['onboarding']),
    ...mapState('profile', ['personalData']),
  },
  mounted() {
    this.hasNextStep = false
  },
  methods: {
    ...mapActions(['setOnboarding']),
    setAlignPlacementDeviceLarge() {
      const minDeviceXLarge = 1200
      const maxDeviceXLarge = 1599

      return window.screen.width >= minDeviceXLarge
        && window.screen.width <= maxDeviceXLarge
          ? 'right'
          : 'bottom'

    },
    nextStep(tour) {
      if (tour.currentStep === tour.steps.length - 1) {
        this.finishTutorial()
        tour.stop()
      } else {
        tour.nextStep()
      }
    },
    previousStep(tour) {
      if (tour.currentStep === 0) {
        this.$router.push({
          name: 'videos',
          params: { channel: this.$route.params.channel }
        })
        this.$emit('previous')
      } else {
        tour.previousStep()
      }
    },
    finishTutorial() {
      this.setOnboarding({
        ...this.onboarding,
        users: {
          ...this.onboarding.users,
          [this.personalData.id]: {
            ...this.onboarding.users[this.personalData.id],
            id: this.personalData.id,
            welcomeActive: false,
            tourActive: false,
            tourCompleted: true,
          },
        },
      })
    },
  },
}
</script>
